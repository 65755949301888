import * as React from 'react';
import style from '../../newFeedPage.module.scss'
import { Autocomplete, Box, TextField, styled } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useNewFeedActions } from '../../../../utils/redux/storeHooks/actionsHooks/useNewFeedActions';
import { useNewFeedData } from '../../../../utils/redux/storeHooks/dataHooks/useNewFeedData';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { options } from '../../../../utils/pages/newFeedPage/daysOptions';

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiOutlinedInput-root': {
    padding: '1px 12px',
    borderRadius: '1px',
    fontFamily: 'Kanit',
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(109, 48, 183, 1)',
    },
  },
})

export const SelectLastDays = () => {
  const { tabId } = useParams();
  const { setDaysOptionForTab } = useNewFeedActions();
  const { tabs } = useNewFeedData();
  const selectedOption = tabs.find(tab => tab.id === tabId)?.daysOption?.title || null;
  const defaultOption = tabs.find(tab => tab.id === 'general')?.daysOption?.title || null;
  const handleAutocompleteChange = (_: React.SyntheticEvent<Element, Event>, newValue: string | null) => {
    const selectedOption = options.find(option => option.title === newValue) || null;
    setDaysOptionForTab({ newValue: selectedOption, tabId })
  }

  return (
    <Box className={style.actions__selectLastDaysWrapper}>
      <Box className={style.actions__inputTitle}>Date</Box>
      <StyledAutocomplete
        clearIcon={null}
        disablePortal
        id="combo-box-demo"
        options={options.filter(option => option.title !== defaultOption).map(option => option.title)}
        sx={{ width: '100%' }}
        defaultValue={defaultOption}
        value={selectedOption}
        onChange={(event, newValue: string | null) => handleAutocompleteChange(event, newValue)}
        ListboxProps={{ sx: { "& li": { fontFamily: "Kanit", fontWeight: 300 } } }}
        renderInput={(params) => <TextField
          placeholder='Date'
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <CalendarMonthIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            ),
          }}
        />}
      />
    </Box>
  )
}

