import { Box, Divider, Typography } from '@mui/material';
import * as React from 'react';
import scss from '../../automation_page.module.scss'

type Props = {
  children: React.ReactElement;
  title: string;
  width?: string;
  isBoxShadow?: boolean;
  offDevider?: boolean;
}

export const AutomationPageSettingsWrapper = ({ children, title, width, isBoxShadow, offDevider }: Props) => {
  return (
    <Box
      className={scss.setting__mainSetting}
      sx={{
        boxShadow: isBoxShadow && 1,
        width: width ? width : '100%'
      }}
    >
      <Typography className={scss.setting__title}>
        {title}
      </Typography>
      {!offDevider && <Divider sx={{ mt: 2, mb: 4 }} />}
      {children}
    </Box>
  )
}

