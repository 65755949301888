import { useAppSelector } from '../typedHooks';

export const useTableImportCSVData = () => {
  const {
    rows,
    loading,
    selectedImports,
    selectedRowForMenu,
    isDeletingProcess,
    uploadingCSV
  } = useAppSelector((state) => state.tableImportCSVReducer);

  return {
    rows,   
    loading,
    selectedImports,
    selectedRowForMenu,
    isDeletingProcess,
    uploadingCSV
  };
};