import React from 'react';
import { FunctionComponent } from "react";
import AccountMenu from '../accountMenu/account'
import styles from "./Header.module.css";
import scss from './header.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { UploadingCSVIndicator } from '../uploadingCSVIndicator/UploadingCSVIndicator';
import { AutomationIndicator } from '../automationIndicator/AutomationIndicator';
import { UploadingSnLinkIndicator } from '../uploadingSnLinkIndicator/UploadingSnLinkIndicator';
import { HeaderNotifications } from './features/HeaderNotifications';
import { CheckNotificationsIndicator } from '../checkNotificationInsdicator/CheckNotificationsIndicator';

const Header: FunctionComponent = () => {
  const location = useLocation();
  const logoShowPaths = [
    '/automation',
    '/prompts-manager',
    '/bot-management',
    '/bot-management/initial-feed',
    '/admin-page/users',
    '/admin-page/teams'
  ]
  const isLogoShow = logoShowPaths.includes(location.pathname)

  return (
    <div className={scss.header}>
      <div className={scss.header1}>
        {isLogoShow && (
          <Link to='/' className={scss.logoWrapper}>
            <img className={scss.logoIcon} alt="" src="/logo.svg" />
          </Link>
        )}
        <UploadingCSVIndicator />
        <UploadingSnLinkIndicator />
        {!isLogoShow && <CheckNotificationsIndicator />}
        <AutomationIndicator />
        <HeaderNotifications />
        <div className={scss.profile}>
          <AccountMenu />
        </div>
      </div>
      <div className={scss.divider}>
      </div>
    </div>
  );
};

export default Header;
