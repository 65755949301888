import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, styled } from '@mui/material';
import * as React from 'react';
import { useAutomationActions } from '../../../../utils/redux/storeHooks/actionsHooks/useAutomationActions';

const CustomFormControl = styled(FormControl)({
  '& label.Mui-focused': {
    color: '#3B2470',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#3B2470',
    opacity: '0.7'
  },
});

const CustomLabelText = styled(ListItemText)({
  '& .MuiListItemText-primary': {
    fontFamily: 'Kanit',
    fontWeight: 300
  },
})

const daysOfWeek = [
  { name: 'Monday', id: 1 },
  { name: 'Tuesday', id: 2 },
  { name: 'Wednesday', id: 3 },
  { name: 'Thursday', id: 4 },
  { name: 'Friday', id: 5 },
  { name: 'Saturday', id: 6 },
  { name: 'Sunday', id: 7 },
]

export const AutomationPageDays = () => {
  const [days, setDays] = React.useState<string[]>([]);
  const { setSelectedDays } = useAutomationActions();

  const handleChange = (event: SelectChangeEvent<typeof days>) => {
    const { target: { value } } = event;
    if (Array.isArray(value)) {
      setDays(value);
      const selectedDays = value.map(item => {
        const currentDay = daysOfWeek.find(day => day.name === item)
        return currentDay.id
      }).join(',');
      setSelectedDays(selectedDays)
    }
  };

  return (
    <CustomFormControl fullWidth disabled>
      <InputLabel id="demo-multiple-checkbox-label">Days</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={days}
        onChange={handleChange}
        input={<OutlinedInput label="Days" />}
        renderValue={(selected) => selected.join(', ')}
      >
        {daysOfWeek.map(day => (
          <MenuItem
            key={day.id}
            value={day.name}
            sx={{ display: 'flex', gap: '10px', }}
          >
            <Checkbox
              sx={{
                padding: 0,
                '&.Mui-checked': {
                  color: '#3B2470',
                },
              }}
              checked={days.indexOf(day.name) > -1}
            />
            <CustomLabelText>{day.name}</CustomLabelText>
          </MenuItem>
        ))}
      </Select>
    </CustomFormControl>
  )
}

