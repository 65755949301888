import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import styles from "../ImportCSV.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React from 'react';
import { useTableImportCSVData } from '../../../../utils/redux/storeHooks/dataHooks/useTableImportCSVData';
import { useTableImportCSVActions } from '../../../../utils/redux/storeHooks/actionsHooks/useTableImportCSVActions';

type Props = {
  onClose: () => void;
}

export const CancelUpload = ({ onClose }: Props) => {
  const { uploadingCSV, isDeletingProcess } = useTableImportCSVData()
  const { deleteCSVAndUpdateList } = useTableImportCSVActions()
  const cancelUpload = () => {
    deleteCSVAndUpdateList({ selectedImports: [uploadingCSV.documentUuid] })
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.title}>
          Cancel upload
        </Box>
        <IconButton aria-label="delete" color="default" onClick={onClose} className={styles.closeButton}>
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>

      <Box className={styles.body}>
        <Box className={styles.body__noSlots}>
          <Typography className={styles.body__text}>
            Are you sure you want to cancel the file <b>{uploadingCSV.fileName}</b> upload?
          </Typography>
        </Box>
      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={cancelUpload}
          className={styles.footer__cancel}>
          {isDeletingProcess
            ? <CircularProgress size={26} sx={{ color: '#6f7d9b' }} /> : (
              <>
                Cancel upload
              </>
            )}
        </Button>
        <Box>
          <Button variant="contained" className={styles.footer__action} onClick={onClose}>
            Continue upload
          </Button>
        </Box>
      </Box>
    </Box >
  );
}

