import React from 'react';
import './index.scss';
import App from './App';
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import reportWebVitals from './reportWebVitals';
import store from './utils/redux/store';
import { Provider } from 'react-redux';
import { AppSnackbar } from './components/appSnackbar/AppSnackbar';
import { AppPopupsList } from './components/appPopupsList/appPopupsList';
import { NotificationLoadingSnackbar } from './components/checkNotificationInsdicator/NotificationLoadingSnackbar';

const muiTheme = createTheme();

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <BrowserRouter>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <Provider store={store} >
          <App />
          <AppSnackbar />
          <AppPopupsList />
          <NotificationLoadingSnackbar />
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
