import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import styles from "../ImportCSV.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React from 'react';
import { usePopupActions } from '../../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTableImportCSVData } from '../../../../utils/redux/storeHooks/dataHooks/useTableImportCSVData';
import { Statuses } from '../../../../types/components/uploadingCSVIndicator/statuses';
import { useTableImportCSVActions } from '../../../../utils/redux/storeHooks/actionsHooks/useTableImportCSVActions';

type Props = {
  onClose: () => void;
}

export const UploadingProcess = ({ onClose }: Props) => {
  const { setImportProcessStep } = usePopupActions()
  const { uploadingCSV } = useTableImportCSVData();
  const { setUploadingCSV } = useTableImportCSVActions()

  const cancelUpload = () => {
    setImportProcessStep('cancelUpload')
  }

  const complete = () => {
    setImportProcessStep('first')
    setUploadingCSV(null)
    onClose()
  }

  if (uploadingCSV.status === Statuses.Uploaded) {
    return (
      <Box className={styles.popup}>
        <Box className={styles.header}>
          <Box className={styles.title}>
            Uploading CSV in progress
          </Box>
          <IconButton aria-label="delete" color="default" onClick={onClose} className={styles.closeButton}>
            <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
          </IconButton>
        </Box>

        <Box className={styles.body}>
          <Box className={styles.body__uploadingProcess}>
            <Box className={styles.body__uploadingProcessLine}>
              <CircularProgress size={22} sx={{ color: '#3B2470' }} />
              <Typography className={styles.body__uploadingProcessText}>
                Your file <b>{uploadingCSV.fileName}</b> is being uploaded. Depending on its size, this process may take some time.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className={styles.footer}>
          <Button
            variant='text'
            onClick={cancelUpload}
            className={styles.footer__cancel}>
            Cancel upload
          </Button>
          <Box>
            <Button className={styles.footer__action} onClick={onClose}>
              Continue upload
            </Button>
          </Box>
        </Box>
      </Box >
    )
  }

  if (uploadingCSV.status === Statuses.Parsing) {
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.title}>
          Uploading CSV in progress
        </Box>
        <IconButton aria-label="delete" color="default" onClick={onClose} className={styles.closeButton}>
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>

      <Box className={styles.body}>
        <Box className={styles.body__uploadingProcess}>
          <Box className={styles.body__uploadingProcessLine}>
            <CheckCircleIcon sx={{ color: '#25660E' }} />
            <Typography className={styles.body__uploadingProcessText}>
              {uploadingCSV.fileName} is uploaded.
            </Typography>
          </Box>
          <Box className={styles.body__uploadingProcessLine}>
            <CircularProgress size={22} sx={{ color: '#3B2470' }} />
            <Typography className={styles.body__uploadingProcessText}>
              The file has been successfully uploaded.
            </Typography>
            <Typography className={styles.body__uploadingProcessText}>
              We&apos;re now adding posts to the Feed. It may take some time.
            </Typography>
          </Box>

        </Box>
      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={cancelUpload}
          className={styles.footer__cancel}>
          Cancel upload
        </Button>
        <Box>
          <Button className={styles.footer__action} onClick={onClose}>
            Continue upload
          </Button>
        </Box>
      </Box>
    </Box >
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.title}>
          Uploading CSV in progress
        </Box>
        <IconButton aria-label="delete" color="default" onClick={onClose} className={styles.closeButton}>
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>

      <Box className={styles.body}>
        <Box className={styles.body__uploadingProcess}>
          <Box className={styles.body__uploadingProcessLine}>
            <CheckCircleIcon sx={{ color: '#25660E' }} />
            <Typography className={styles.body__uploadingProcessText}>
              {uploadingCSV.fileName} is uploaded.
            </Typography>
          </Box>
          <Box className={styles.body__uploadingProcessLine}>
            <CheckCircleIcon sx={{ color: '#25660E' }} />
            <Typography className={styles.body__uploadingProcessText}>
              Postsn successfully added.
            </Typography>
          </Box>

        </Box>
      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={cancelUpload}
          className={styles.footer__cancel}>
          Cancel upload
        </Button>
        <Box>
          <Button className={styles.footer__action} onClick={complete}>
            Complete
          </Button>
        </Box>
      </Box>
    </Box >
  );
}

