import React, { useMemo } from 'react';
import styles from "../RunAutomation.module.scss";
import { Box, Checkbox, Divider, InputAdornment, Typography } from '@mui/material';
import { StyledTextField } from './StyledTextField';
import SearchIcon from '@mui/icons-material/Search';
import { useAutomationData } from '../../../../utils/redux/storeHooks/dataHooks/useAutomationData';
import { useAutomationActions } from '../../../../utils/redux/storeHooks/actionsHooks/useAutomationActions';
import debounce from 'lodash/debounce';

export const ActionsPopUp = () => {
  const {
    isShowOnlyExcluded,
    isGetLeadsToExcludeRequest,
    leadsList,
  } = useAutomationData();
  const {
    setShowOnlyExcluded,
    setInputName
  } = useAutomationActions();

  const handleChangeShowOnlyExclude = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowOnlyExcluded(event.target.checked);
  }

  const onNameChange = useMemo(
    () =>
      debounce((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputName(event.target.value)
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <Typography className={styles.content__subTitle}>
        Your comments will be sent directly to LinkedIn
      </Typography>
      <Divider />
      <Box className={styles.content__mainSwitcherContainer}>
        <Typography className={styles.content__mainSwitcherTitle}>
          Exclude leads from comments automation
        </Typography>
      </Box>
      <Box className={styles.content__searchContainer}>
        <StyledTextField
          variant="standard"
          placeholder='Search'
          onChange={(e) => onNameChange(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Divider orientation="vertical" sx={{ height: 24 }} />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
          <Typography sx={{ fontFamily: 'Kanit', fontSize: 14 }}>
            already excluded
          </Typography>
          <Checkbox
            sx={{
              height: 22,
              width: 22,
              color: '#3B2470',
              '&.Mui-checked': { color: '#3B2470' }
            }}
            disabled={isGetLeadsToExcludeRequest && !leadsList.length}
            onChange={handleChangeShowOnlyExclude}
            checked={isShowOnlyExcluded}
          />
        </Box>
      </Box>
    </>
  )
}

