import React, { useEffect, useState } from 'react'
import scss from './PromptSettingPage.module.scss'
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, LinearProgress, TextField } from '@mui/material'
import { Get, Post } from '../../utils/general/https'
import { useNotificationActions } from '../../utils/redux/storeHooks/actionsHooks/useNotificationActions'
import Header from '../../components/header/Header'
import { useAutomationData } from '../../utils/redux/storeHooks/dataHooks/useAutomationData'
import Cookies from 'universal-cookie'

const promptOrder = [
  "PreloadReply",
  "🧐 Give a meaningful idea",
  "🥳 Celebrate the success",
  "🤝 Add a CTA",
  "🤔 Appreciate and ask a question",
  "IceBreakerForInvite",
  "iceBreakerDM"
]

const visiblePromptNames = [
  'Automation prompt',
  "🧐 Give a meaningful idea",
  "🥳 Celebrate the success",
  "🤝 Add a CTA",
  "🤔 Appreciate and ask a question",
  'Prompt for invites',
  'Prompt for messages'
]

const promptDescription = [
  'This prompt is used for comments automation. It is triggered when you run the automation.',
  'The prompt is used in Extension to send comments from your LinkedIn account. Example - "Provide short and meaningful commentary on the topic at hand in 1 or 2 sentences. Back it up with facts if possible."',
  'The prompt is used in Extension to send comments from your LinkedIn account. Example - "Congratulate on a new job position, companies achievements, career promotion, or birthday in 1 or 2 short sentences."',
  'The prompt is used in Extension to send comments from your LinkedIn account. Example - "Summarize the post shortly in 1 sentence and gently offer to continue conversation."',
  'The prompt is used in Extension to send comments from your LinkedIn account. Example - "Appreciate the opinion in the post, add a couple of details and ask a simple clarifying question all in 1 or 2 sentences."',
  'This prompt is designed for inviting your 2nd connections on LinkedIn.',
  'This prompt is designed for sending DM to your 1st connections on LinkedIn.'
]

const promptWarning = [
  "To generate qualified comments, this prompt uses post summary, user name, company name, and lead's thoughts on reposts.",
  '',
  '',
  '',
  '',
  "To generate the most relevant invite note, the prompt uses the following variables: user name, post summary, comments, and the lead's reply.",
  "To generate the most relevant message, the prompt uses the following variables: post summary, comments, and the lead's reply."
]

type PromptRes = {
  id: number;
  name: string;
  prompt: string;
  show: boolean;
  usage: number;
  userUuid: string;
  promptDescription: string;
  promptWarning: string;
  visibleName: string;

}

export const PromptSettingPage = () => {
  const { useStaticPrompt } = useAutomationData();
  const [promptsList, setPromptsList] = useState<PromptRes[]>([])
  const [staticPrompt, setStaticPrompt] = useState<PromptRes>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [checked, setChecked] = React.useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isError, setError] = useState('')
  const [updatingPromptId, setUpdatingPromptId] = useState<number | string | null>(null)
  const { openSnackBar } = useNotificationActions()

  const handleValueChanges = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
    const { value } = e.target;
    setPromptsList((prevState) => {
      const updatedList = [...prevState];
      updatedList[index] = { ...updatedList[index], prompt: value };
      return updatedList;
    });
  };

  const handleStaticPromptValueChanges = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    setStaticPrompt((prevState) => {
      const staticCopyPrompt = { ...prevState };
      staticCopyPrompt.prompt = value;
      return staticCopyPrompt;
    });
  };


  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const updatePrompt = async (id: number | string, prompt: string, needUpdateAutomationConfig?: boolean, useStatic?: boolean,) => {
    setUpdatingPromptId(id)
    try {
      if (prompt) {
        const body = {
          id: id,
          prompt: prompt,
          needUpdateAutomationConfig: needUpdateAutomationConfig || false,
          useStatic: useStatic || false
        }

        await Post(`/prompt/update-prompt`, body, true)
        openSnackBar({
          severenity: 'success',
          message: 'Your prompt has been saved successfully.'
        })
      } else {
        openSnackBar({
          severenity: 'error',
          alertTitle: 'Prompts updated error',
          message: 'Your prompt is empty'
        })
      }

    } catch (error) {
      openSnackBar({
        severenity: 'error',
        alertTitle: 'Prompts updated error',
        message: error?.message || 'Something went wrong.'
      })
    } finally {
      setIsLoading(false)
      setUpdatingPromptId(null)
    }
  }

  const getPromptList = async () => {
    try {
      const response = await Get('/prompt/get-full-prompt-list', true)
      let sortedData: PromptRes[] = [];
      promptOrder.forEach((prompt) => {
        const currentPrompt = response.find((elem: PromptRes) => elem.name === prompt)
        if (currentPrompt) {
          sortedData.push(currentPrompt)
        }
      })
      sortedData = sortedData.map((elem, index) => {
        elem.promptDescription = promptDescription[index]
        elem.promptWarning = promptWarning[index]
        elem.visibleName = visiblePromptNames[index]
        return elem;
      })
      setPromptsList(sortedData)

      const staticPrompt = response.find((elem: PromptRes) => elem.name === 'staticPreloadReply')
      staticPrompt.visibleName = 'Static automation prompt'
      staticPrompt.promptDescription = 'This prompt is used for static comments automation. It can triggered when you run the automation. This prompt uses variables: __LEADNAME__ , __COMPANYNAME__ , __POSTSUMMARY__. *Example: __POSTSUMMARYWORDSCOUNT:4__'
      staticPrompt.promptWarning = 'To use static comment in automation select checkbox and save prompt'
      setStaticPrompt(staticPrompt)
    } catch (error) {
      openSnackBar({
        severenity: 'error',
        alertTitle: 'Prompts loading error',
        message: error?.message || 'Something went wrong'
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getPromptList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setChecked(useStaticPrompt)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useStaticPrompt])



  const cookies = new Cookies();
  const userUuid = cookies.get('ht')

  return (
    <Box className={scss.pageWrapper}>
      <Header />
      {isLoading && <LinearProgress color='inherit' sx={{ marginTop: '-25px', width: '100%' }} />}
      <Box className={scss.container}>
        <div className={scss.listOfPrompts}>
          {staticPrompt && (userUuid === 'd30ebe15-ad76-43f2-82bd-f3a797c39266' || userUuid === 'b74eb2d2-8fc9-4756-981d-13bb17320be9') && <div className={scss.items}>
            {staticPrompt.visibleName}
            <div className={scss.promptDescription}>{staticPrompt.promptDescription}</div>
            {staticPrompt.promptWarning && <div className={scss.promptWarning}>{staticPrompt.promptWarning}</div>}
            <TextField
              value={staticPrompt.prompt}
              onChange={(e) => handleStaticPromptValueChanges(e)}
              multiline
              rows={8}
              maxRows={Infinity}
              fullWidth={true}
              sx={{
                borderColor: 'neutral/gray !important',
                borderRadius: 20,
                mb: '18px',
                mt: '18px'
              }}
              InputLabelProps={{
                sx: {
                  backgroundColor: 'red',
                  color: '#003566',
                  textTransform: 'capitalize',
                },
              }}
            />
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '24px' }}>
              <FormControlLabel
                control={<Checkbox
                  sx={{ padding: '0 6px' }}
                  checked={checked}
                  onChange={handleChangeCheckBox}
                  inputProps={{ 'aria-label': 'controlled' }}
                />}
                label="Use static comments"
                sx={{
                  fontFamily: 'Kanit',
                  '& .MuiFormControlLabel-label': {
                    fontFamily: 'Kanit',
                  }
                }}
              />
              <Button
                variant="contained"
                type="submit"
                className={scss.saveBtn}
                onClick={() => updatePrompt(
                  staticPrompt.id,
                  staticPrompt.prompt,
                  true,
                  checked
                )}
                disabled={updatingPromptId === staticPrompt.id}
                sx={{
                  backgroundColor: '#3B2470',
                  '&:hover': {
                    backgroundColor: '#3b2470eb',
                    boxShadow: 6
                  }
                }}
              >
                {updatingPromptId === staticPrompt.id
                  ? <CircularProgress size={26} />
                  : 'Save'
                }
              </Button>
            </Box>
          </div>
          }

          {promptsList.map((item, index) => {
            return (
              <React.Fragment key={item.id}>
                <div className={scss.items}>
                  {item.visibleName}
                  <div className={scss.promptDescription}>{item.promptDescription}</div>
                  {item.promptWarning && <div className={scss.promptWarning}>{item.promptWarning}</div>}
                  <TextField
                    value={item.prompt}
                    onChange={(e) => handleValueChanges(e, index)}
                    multiline
                    rows={8}
                    maxRows={Infinity}
                    fullWidth={true}
                    sx={{
                      borderColor: 'neutral/gray !important',
                      borderRadius: 20,
                      mb: '18px',
                      mt: '18px'
                    }}
                    InputLabelProps={{
                      sx: {
                        backgroundColor: 'red',
                        color: '#003566',
                        textTransform: 'capitalize',
                      },
                    }}
                  />
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant="contained"
                      type="submit"
                      className={scss.saveBtn}
                      onClick={() => updatePrompt(item.id, item.prompt)}
                      disabled={updatingPromptId === item.id}
                      sx={{
                        backgroundColor: '#3B2470',
                        '&:hover': {
                          backgroundColor: '#3b2470eb',
                          boxShadow: 6
                        }
                      }}
                    >
                      {updatingPromptId === item.id
                        ? <CircularProgress size={26} />
                        : 'Save'
                      }
                    </Button>
                  </Box>
                </div>
              </React.Fragment>
            )
          })}
        </div>
      </Box>
    </Box>

  )
}

