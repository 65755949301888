import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Get, Post } from '../../../general/https';
import { DeleteCSVPayload, DeleteCSVResponse, GetImportsListResponse, TableImportCSV } from './types';
import { TableImportCSVData } from '../../../../types/pages/dashboardLeadsPeoplePage/tableImportCSV/tableImportCSVData';
import { uploadCSV } from '../popupSlice/thunks';
import { statuses } from '../../../components/uploadingCSVIndicator/statuses';

export const getImportsList = createAsyncThunk<GetImportsListResponse>(
  'tableImportCSVSlice/getImportsList',
  async (_, {rejectWithValue}) => {
    try {
      const response = await Get(`/csv-import/list-of-files`, true)

      if(response?.data?.error) {
        throw new Error('Something went wrong, try again later.')
      }
      return response
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const deleteCSVAndUpdateList = createAsyncThunk<DeleteCSVResponse, DeleteCSVPayload>(
  'tableImportCSVSlice/deleteCSVAndUpdateList',
  async ({selectedImports}, {rejectWithValue}) => {
    try {
      const response = await Post(`/csv-import/delete`, { listOfDocumentsUuid: selectedImports }, true)

      if(response?.status === 400) {
        throw new Error(response.data.reason)
      }

      if(response?.data?.error) {
        throw new Error('Something went wrong, try again later.')
      }

      const newList = await Get(`/csv-import/list-of-files`, true)

      if(newList?.data?.error) {
        throw new Error('Something went wrong, try again later.')
      }

      return newList
    } catch(error) {
      return rejectWithValue(error.message)
    }
  }
)

const initialState: TableImportCSV = {
  rows: [],
  loading: false,
  selectedImports: [],
  selectedRowForMenu: null,
  isDeletingProcess: false,
  uploadingCSV: null,
};

export const tableImportCSVSlice = createSlice({
  name: 'tableTeamManagementSlice',
  initialState,
  reducers: {
    setRows: (state, { payload }: PayloadAction<TableImportCSVData[]>) => {
      state.rows = payload;
    },    
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },   
    setSelectedImports: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedImports = payload;
    },
    toggleSelectedImports: (state, { payload }: PayloadAction<string>) => {
      if (state.selectedImports.includes(payload)) {
        state.selectedImports = state.selectedImports.filter(id => id !== payload)
        return;
      }

      state.selectedImports.push(payload);
    },
    setSelectedRowForMenu: (state, { payload }: PayloadAction<TableImportCSVData | null>) => {
      state.selectedRowForMenu = payload;
    },
    setDeletingProcess: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingProcess = payload;
    },
    setUploadingCSV: (state, { payload }: PayloadAction<TableImportCSVData | null>) => {
      state.uploadingCSV = payload;
    },
  },
  extraReducers: (builder) => {
    //Get Import List
    builder.addCase(getImportsList.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(getImportsList.fulfilled, (state, action) => {
      state.rows = action.payload;
      state.uploadingCSV = action.payload.find(file => statuses.includes(file.status)) || null;
      state.loading = false;
    })
    builder.addCase(getImportsList.rejected, (state, action) => {
      state.loading = false;
      state.rows = [];
    })
     //Delete and update Import List
     builder.addCase(deleteCSVAndUpdateList.pending, (state, action) => {
      state.isDeletingProcess = true;
    })
    builder.addCase(deleteCSVAndUpdateList.fulfilled, (state, action) => {
      state.rows = action.payload;
      state.isDeletingProcess = false;
      state.selectedImports = [];
      const uploadingCSVId = state.uploadingCSV?.documentUuid
      if (action.meta.arg.selectedImports.includes(uploadingCSVId)) {
        state.uploadingCSV = null;
      }
    })
    builder.addCase(deleteCSVAndUpdateList.rejected, (state) => {
      state.isDeletingProcess = false;
      state.selectedImports = [];
    })
    //Upload CSV
    builder.addCase(uploadCSV.fulfilled, (state, action) => {
      state.rows.push(action.payload);
      state.uploadingCSV = action.payload
    })
  }
});

export const tableImportCSVActions = tableImportCSVSlice.actions;
export const tableImportCSVReducer = tableImportCSVSlice.reducer;