import React from 'react';
import styles from "./ImportCSV.module.scss";
import { usePopupData } from '../../../utils/redux/storeHooks/dataHooks/usePopupData';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Box } from '@mui/material';
import { UploadFirstStep } from './features/UploadFirstStep';
import { UploadNoSlots } from './features/UploadNoSlots';
import { CancelUpload } from './features/CancellUpload';
import { UploadingProcess } from './features/UploadingProcess';
import { UploadError } from './features/UploadError';

export const PopupImportCSV = () => {
  const { setPopupImportCSVOpen } = usePopupActions();
  const { importProcessStep } = usePopupData();

  const closePopup = () => {
    setPopupImportCSVOpen(false);
  };

  if (importProcessStep === 'first') {
    return <UploadFirstStep onClose={closePopup} />
  }

  if (importProcessStep === 'noSlots') {
    return <UploadNoSlots onClose={closePopup} />
  }

  if (importProcessStep === 'uploadingProcess') {
    return <UploadingProcess onClose={closePopup} />
  }

  if (importProcessStep === 'cancelUpload') {
    return <CancelUpload onClose={closePopup} />
  }

  if (importProcessStep === 'uploadingError') {
    return <UploadError onClose={closePopup} />
  }

  return <Box className={styles.popup} />


};
