import { HeadCell } from "../../../../types/pages/dashboardLeadsPeoplePage/tableImportCSV/headCell";

export const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    label: 'Name',
    disableSorting: false,
    showToolTip: false,
    textForToolTip: '',
  },
  {
    id: 'added',
    label: 'Added',
    disableSorting: false,
    showToolTip: false,
    textForToolTip: '',
  },
  {
    id: 'leads',
    label: 'Leads',
    disableSorting: true,
    showToolTip: true,
    textForToolTip: '',
  },
  {
    id: 'status',
    label: 'Status',
    disableSorting: true,
    showToolTip: true,
    textForToolTip: '',
  },  
];