import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationSliceInitialState, OpenSnackBarPayload } from './types';
import { deleteLeadsAndUpdatedLeadList, exportLeads, getLeadList, sendErrorNotification, stopWorkUploadSnLink, updateFullLeadInfo } from '../tableLeadsSlice/thunks';
import { generateIceBreake, sendDM, sendInvitation, uploadCSV } from '../popupSlice/thunks';
import { deleteTeamMemberAndUpdateTeamList } from '../tableTeamManagmentSlice/teamManagementSlice';
import { deleteCSVAndUpdateList } from '../tableImportCSVSlice/tableImportCSVSlice';
import { createNewTab, deletePost, deleteTab, generateMessage, getFeed, sendMessage, updateTab, updateTitleTab } from '../newFeedSlice/thunks';
import { getAutomationConfig, runAutomation, updateConfig } from '../automationPageSlice/thunks';
import { checkFeedDaily, checkFeedInitial, loadLeadsSnGet, stopWork } from '../botManagementSlice/thunks';
import { checkActivityByNotification } from '../leadsNotificationSlice/thunks';
import { createTeam, deleteAdditionalWrongInfo, deleteTeam, deleteUser, sendEmailCredentials, sendEmailExtension, updateTeam, updateUser } from '../adminPageSlice/thunks';

const initialState: NotificationSliceInitialState = {
  isSnackbarOpen: false,
  vertical: 'bottom',
  horizontal: 'right',
  message: '',
  severenity: 'info',
  alertTitle: '',
  duration: 3000,
};

export const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState,
  reducers: {
    openSnackBar: (state, {payload} : PayloadAction<OpenSnackBarPayload>) => {
      state.isSnackbarOpen = false;
      state.alertTitle = '';
      state.severenity = 'info';
      state.message = '';
      state.duration = 3000;
      state.vertical = 'bottom';
      state.horizontal = 'right';
      state.alertTitle = payload.alertTitle;
      state.severenity = payload.severenity;
      state.message = payload.message;
      state.duration = payload.duration || 3000;
      state.vertical = payload.vertical || 'bottom';
      state.horizontal = payload.horizontal || 'right';
      state.isSnackbarOpen = true;     
    },
    closeSnackBar: (state, {payload} : PayloadAction<OpenSnackBarPayload>) => {
      state.isSnackbarOpen = false;  
    },     
  },
  extraReducers: (builder) => {
    //Get Lead List
    builder.addCase(getLeadList.rejected, (state) => {      
      state.severenity = 'error';
      state.message = 'Something went wrong, try again later.'
      state.isSnackbarOpen = true;
    })
    //Delete And Update Lead List
    builder.addCase(deleteLeadsAndUpdatedLeadList.fulfilled, (state) => {      
      state.severenity = 'success';
      state.message = 'Delete successfuly'
      state.isSnackbarOpen = true;
    })
    builder.addCase(deleteLeadsAndUpdatedLeadList.rejected, (state) => {
      state.severenity = 'error';
      state.message = 'Deleting error.'
      state.isSnackbarOpen = true;    
    })
    //Update Full Lead Info
    builder.addCase(updateFullLeadInfo.fulfilled, (state, action) => {
      const firstName = action.meta.arg.firstName;
      const lastName = action.meta.arg.lastName;      
      state.severenity = 'success';
      state.message = `${firstName} ${lastName} successfully updated`
      state.isSnackbarOpen = true;
    })
    builder.addCase(updateFullLeadInfo.rejected, (state, action) => {
      state.severenity = 'error';
      state.alertTitle = 'Error'
      state.message = `${action.payload}`
      state.isSnackbarOpen = true;    
    })
    //Generate Ice Break
    builder.addCase(generateIceBreake.rejected, (state, action) => {
      state.severenity = 'warning';
      state.message = 'Can not generate message.'
      state.isSnackbarOpen = true; 
    })
    //delete team member
    builder.addCase(deleteTeamMemberAndUpdateTeamList.fulfilled, (state, action) => {
      state.severenity = 'success';
      state.message = 'Team member successfully deleted'
      state.isSnackbarOpen = true;
    })
    builder.addCase(deleteTeamMemberAndUpdateTeamList.rejected, (state) => {
      state.severenity = 'error';
      state.message = 'Team member can not delete.'
      state.isSnackbarOpen = true;
    })
    //Upload CSV
    builder.addCase(uploadCSV.fulfilled, (state, action) => {
      state.alertTitle = 'Upload successfully.'
      state.severenity = 'success';
      state.message = `File ${action.payload.fileName} uploaded and has been start processing.`
      state.isSnackbarOpen = true;
    })
    builder.addCase(uploadCSV.rejected, (state, action) => {
      state.alertTitle = 'Upload error.'
      state.severenity = 'error';
      state.message = `${action.payload}`
      state.isSnackbarOpen = true;
    })
    //Delete CSV
    builder.addCase(deleteCSVAndUpdateList.fulfilled, (state) => {
      state.alertTitle = 'Success.'
      state.severenity = 'success';
      state.message = 'CSV file successfully deleted.'
      state.isSnackbarOpen = true;
    })
    builder.addCase(deleteCSVAndUpdateList.rejected, (state, action) => {
      state.alertTitle = 'Deleting CSV file error.'
      state.severenity = 'error';
      state.message = `${action.payload}`
      state.isSnackbarOpen = true;
    })
    //Get all feed
    builder.addCase(getFeed.rejected, (state, action) => {
      state.alertTitle = 'Error loading feed.';
      state.severenity = 'error';
      state.message = `${action.payload}`;
      state.isSnackbarOpen = true;
    })
    //Save tab
    builder.addCase(createNewTab.fulfilled, (state, action) => {
      state.alertTitle = 'Success.';
      state.severenity = 'success';
      state.message = `Tab ${action.payload.title} successfully created`;
      state.isSnackbarOpen = true;
    })
    builder.addCase(createNewTab.rejected, (state, action) => {
      state.alertTitle = 'Error.';
      state.severenity = 'error';
      state.message = `${action.payload}`;
      state.isSnackbarOpen = true;
    })
    //Update tab
    builder.addCase(updateTab.fulfilled, (state, action) => {
      state.alertTitle = 'Successfully.';
      state.severenity = 'success';
      state.message = `Tab ${action.payload.title} successfully updated`;
      state.isSnackbarOpen = true;
    })
    builder.addCase(updateTab.rejected, (state, action) => {
      state.alertTitle = 'Error updating tab.';
      state.severenity = 'error';
      state.message = `${action.payload}`;
      state.isSnackbarOpen = true;
    })
    //Update tab title
    builder.addCase(updateTitleTab.fulfilled, (state, action) => {
      state.alertTitle = 'Successfully.';
      state.severenity = 'success';
      state.message = `Tab ${action.payload.title} successfully updated`;
      state.isSnackbarOpen = true;
    })
    builder.addCase(updateTitleTab.rejected, (state, action) => {
      state.alertTitle = 'Error updating tab.';
      state.severenity = 'error';
      state.message = `${action.payload}`;
      state.isSnackbarOpen = true;
    })
    //Delete tab
    builder.addCase(deleteTab.fulfilled, (state, action) => {
      state.alertTitle = 'Successfully.';
      state.severenity = 'success';
      state.message = `Tab successfully deleted`;
      state.isSnackbarOpen = true;
    })
    builder.addCase(deleteTab.rejected, (state, action) => {
      state.alertTitle = 'Error deleting tab.';
      state.severenity = 'error';
      state.message = `${action.payload}, try again later.`;
      state.isSnackbarOpen = true;
    })
    //Generate message
    builder.addCase(generateMessage.rejected, (state, action) => {
      state.alertTitle = 'Error generating comment.';
      state.severenity = 'error';
      state.message = `Cannot generate comment. Try again later.`;
      state.isSnackbarOpen = true;
    }) 
    //Send message
    builder.addCase(sendMessage.fulfilled, (state, action) => {
      state.alertTitle = 'Success.';
      state.severenity = 'success';
      state.message = `Comment sent successfully.`;
      state.isSnackbarOpen = true;
    })
    builder.addCase(sendMessage.rejected, (state, action) => {
      state.alertTitle = 'Error sending comment.';
      state.severenity = 'error';
      state.message = `${action.payload}`;
      state.isSnackbarOpen = true;
    })
    //Delete post
    builder.addCase(deletePost.fulfilled, (state, action) => {
      state.alertTitle = 'Success.';
      state.severenity = 'success';
      state.message = `Post deleted successfully.`;
      state.isSnackbarOpen = true;
    })
    builder.addCase(deletePost.rejected, (state, action) => {
      state.alertTitle = 'Error deleting post.';
      state.severenity = 'error';
      state.message = `Cannot delete post. Try again later.`;
      state.isSnackbarOpen = true;
    })
    //Run automation
    builder.addCase(runAutomation.fulfilled, (state, action) => {
      if(action.meta.arg.isAutomationRunning) {
        state.alertTitle = 'Your automation is running now!';
        state.severenity = 'success';
        state.message = `Check your excluded leads in Automation page settings.`;
        state.isSnackbarOpen = true;
      }
    })
    builder.addCase(runAutomation.rejected, (state, action) => {
      state.alertTitle = 'Error automation running.';
      state.severenity = 'error';
      state.message = `Try again later.`;
      state.isSnackbarOpen = true;
    })
    //Get automation config
    builder.addCase(getAutomationConfig.rejected, (state) => {
      state.alertTitle = 'Error geting automation config.';
      state.severenity = 'error';
      state.message = `Try again later.`;
      state.isSnackbarOpen = true;
    })
    //Update config
    builder.addCase(updateConfig.fulfilled, (state, action) => {
      state.alertTitle = 'Success.';
      state.severenity = 'success';
      state.message = 'Config updated successfully.';
      state.isSnackbarOpen = true;
    })
    //runLoadLeadsSnGet
    builder.addCase(loadLeadsSnGet.fulfilled, (state, action) => {
      if(action.payload.isRunLoadLeads) {
        state.alertTitle = 'Success.';
        state.severenity = 'success';
        state.message = 'Loading leads run successfully.';
        state.isSnackbarOpen = true; 
      }
    })
    builder.addCase(loadLeadsSnGet.rejected, (state, action) => {
      state.alertTitle = 'Error running load leads.';
      state.severenity = 'error';
      state.message = `${action.payload}`;
      state.isSnackbarOpen = true;
    })
    //StopWorking
    builder.addCase(stopWork.fulfilled, (state) => {
      state.alertTitle = 'Success.';
      state.severenity = 'success';
      state.message = 'Stopped successfully.';
      state.isSnackbarOpen = true;     
    })
    builder.addCase(stopWork.rejected, (state, action) => {
      state.alertTitle = 'Error when stopped work.';
      state.severenity = 'error';
      state.message = `${action.payload}`;
      state.isSnackbarOpen = true;
    })
    //ChekFeedInitial
    builder.addCase(checkFeedInitial.fulfilled, (state) => {
      state.alertTitle = 'Success.';
      state.severenity = 'success';
      state.message = 'Run check feed initial successfully.';
      state.isSnackbarOpen = true;
    })
    builder.addCase(checkFeedInitial.rejected, (state, action) => {
      state.alertTitle = 'Error when running check feed initial.';
      state.severenity = 'error';
      state.message = `${action.payload}`;
      state.isSnackbarOpen = true;
    })
    //ChekFeedDaily
    builder.addCase(checkFeedDaily.fulfilled, (state) => {
      state.alertTitle = 'Success.';
      state.severenity = 'success';
      state.message = 'Run check feed daily successfully.';
      state.isSnackbarOpen = true;
    })
    builder.addCase(checkFeedDaily.rejected, (state, action) => {
      state.alertTitle = 'Error when running check feed daily.';
      state.severenity = 'error';
      state.message = `${action.payload}`;
      state.isSnackbarOpen = true;
    })
    //SendNotificationErrorWhenParsingSnLink
    builder.addCase(sendErrorNotification.fulfilled, (state) => {
      state.alertTitle = 'Success.';
      state.severenity = 'success';
      state.message = 'Your notification sent successfully.';
      state.isSnackbarOpen = true;
    })
    builder.addCase(sendErrorNotification.rejected, (state) => {
      state.alertTitle = 'Error.';
      state.severenity = 'error';
      state.message = `Error when sending notification`;
      state.isSnackbarOpen = true;
    })
    //StopWorkForUloadingSnLink
    builder.addCase(stopWorkUploadSnLink.fulfilled, (state) => {
      state.alertTitle = 'Success.';
      state.severenity = 'success';
      state.message = 'Stop uploading successfully.';
      state.isSnackbarOpen = true;
    })
    builder.addCase(stopWorkUploadSnLink.rejected, (state) => {
      state.alertTitle = 'Error.';
      state.severenity = 'error';
      state.message = `Can not stop uploading, try again later.`;
      state.isSnackbarOpen = true; 
    })
    //Send invitation
    builder.addCase(sendInvitation.fulfilled, (state, action) => {
      state.alertTitle = 'Success.';
      state.severenity = 'success';
      state.message = 'Invitation sent successfully.';
      state.isSnackbarOpen = true;    
    })
    builder.addCase(sendInvitation.rejected, (state, action) => {
      if(typeof action.payload === 'string' && action.payload.includes('429')) {
        state.alertTitle = 'LinkedIn warning';
        state.severenity = 'warning';
        state.message = `Monthly limit for personalized invites reached. Try sending without a note.`;
        state.isSnackbarOpen = true;
        return
      }
      state.alertTitle = 'Error';
      state.severenity = 'error';
      state.message = `${action.payload}`;
      state.isSnackbarOpen = true;
    })
    //Send DM
    builder.addCase(sendDM.fulfilled, (state, action) => {
      state.alertTitle = 'Success.';
      state.severenity = 'success';
      state.message = 'DM sent successfully.';
      state.isSnackbarOpen = true;    
    })
    builder.addCase(sendDM.rejected, (state, action) => {
      state.alertTitle = 'Error';
      state.severenity = 'error';
      state.message = `${action.payload}`;
      state.isSnackbarOpen = true;
    })
    //Check lead Notifications
    builder.addCase(checkActivityByNotification.fulfilled, (state, action) => {
      state.alertTitle = 'Success.';
      state.severenity = 'success';
      state.message = `Your notifications updated successfully`;
      state.isSnackbarOpen = true;
    })
    builder.addCase(checkActivityByNotification.rejected, (state, action) => {
      state.alertTitle = 'Error.';
      state.severenity = 'error';
      state.message = `${action.payload}`;
      state.isSnackbarOpen = true;
    })
    //Update User
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.alertTitle = 'Success.';
      state.severenity = 'success';
      state.message = `User ${action.payload.user.firstName} ${action.payload.user.lastName} updated successfully`;
      state.isSnackbarOpen = true;
    })
    builder.addCase(updateUser.rejected, (state, action) => {
      state.alertTitle = 'Error.';
      state.severenity = 'error';
      state.message = `${action.payload}`;
      state.isSnackbarOpen = true;
    })
    //Delete User
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.alertTitle = 'Success.';
      state.severenity = 'success';
      state.message = `User deleted successfully`;
      state.isSnackbarOpen = true;
    })
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.alertTitle = 'Error.';
      state.severenity = 'error';
      state.message = `${action.payload}`;
      state.isSnackbarOpen = true;
    })
    //Create Team
    builder.addCase(createTeam.fulfilled, (state, action) => {
      state.alertTitle = 'Success.';
      state.severenity = 'success';
      state.message = `Team create successfully`;
      state.isSnackbarOpen = true;
    })
    builder.addCase(createTeam.rejected, (state, action) => {
      state.alertTitle = 'Error.';
      state.severenity = 'error';
      state.message = `${action.payload}`;
      state.isSnackbarOpen = true;
    })
    //Delete Team
    builder.addCase(deleteTeam.fulfilled, (state, action) => {
      state.alertTitle = 'Success.';
      state.severenity = 'success';
      state.message = `Team deleted successfully`;
      state.isSnackbarOpen = true;
    })
    builder.addCase(deleteTeam.rejected, (state, action) => {
      state.alertTitle = 'Error.';
      state.severenity = 'error';
      state.message = `${action.payload}`;
      state.isSnackbarOpen = true;
    })
    //Update Team
    builder.addCase(updateTeam.fulfilled, (state, action) => {
      state.alertTitle = 'Success.';
      state.severenity = 'success';
      state.message = `Team updated successfully`;
      state.isSnackbarOpen = true;
    })
    builder.addCase(updateTeam.rejected, (state, action) => {
      state.alertTitle = 'Error.';
      state.severenity = 'error';
      state.message = `${action.payload}`;
      state.isSnackbarOpen = true;
    })
    //Send extension
    builder.addCase(sendEmailExtension.fulfilled, (state, action) => {
      if(action.payload.isSend) {
      state.alertTitle = 'Success.';
      state.severenity = 'success';
      state.message = `Email with link to extension sent successfully`;
      state.isSnackbarOpen = true;
      }else {
        state.alertTitle = 'Warning.';
        state.severenity = 'warning';
        state.message = `Email not sending, try again later`;
        state.isSnackbarOpen = true;
      }
    })
    builder.addCase(sendEmailExtension.rejected, (state, action) => {
      state.alertTitle = 'Error.';
      state.severenity = 'error';
      state.message = `${action.payload}`;
      state.isSnackbarOpen = true;
    })
    //Send credentials
    builder.addCase(sendEmailCredentials.fulfilled, (state, action) => {
      if(action.payload.isSend) {
        state.alertTitle = 'Success.';
        state.severenity = 'success';
        state.message = `Credentials sent successfully`;
        state.isSnackbarOpen = true;
      } else {
        state.alertTitle = 'Warning.';
        state.severenity = 'warning';
        state.message = `Email not sending, try again later`;
        state.isSnackbarOpen = true;
      }
    })
    builder.addCase(sendEmailCredentials.rejected, (state, action) => {
      state.alertTitle = 'Error.';
      state.severenity = 'error';
      state.message = `${action.payload}`;
      state.isSnackbarOpen = true;
    })
    //Delete additional info
    builder.addCase(deleteAdditionalWrongInfo.fulfilled, (state, action) => {
      if(action.payload.isDeleted) {
        state.alertTitle = 'Success.';
        state.severenity = 'success';
        state.message = `Additional info clear successfully`;
        state.isSnackbarOpen = true;
      }
    })
    builder.addCase(deleteAdditionalWrongInfo.rejected, (state, action) => {
      state.alertTitle = 'Error.';
      state.severenity = 'error';
      state.message = `${action.payload}`;
      state.isSnackbarOpen = true;
    }) 
    //Export leads
    builder.addCase(exportLeads.fulfilled, (state) => {
      state.alertTitle = 'Export successful';
      state.severenity = 'success';
      state.message = `Please, check download folder`;
      state.isSnackbarOpen = true;  
    })
    builder.addCase(exportLeads.rejected, (state) => {
      state.alertTitle = 'Error when exporting.';
      state.severenity = 'error';
      state.message = `Try again later`;
      state.isSnackbarOpen = true; 
    })    
  }  
});

export const notificationSliceActions = notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;
