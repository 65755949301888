import { useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useAppDispatch } from '../typedHooks';
import { automationActions } from '../../slices/automationPageSlice/automationSlice';
import { getAutomationConfig, getLeadsToExclude, runAutomation, updateConfig } from '../../slices/automationPageSlice/thunks';

const rootActions = {
  ...automationActions,
  getAutomationConfig,
  updateConfig,
  runAutomation,
  getLeadsToExclude,
};

export const useAutomationActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
};