import { Box, Button, IconButton, Typography } from '@mui/material';
import styles from "../ImportCSV.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React from 'react';
import { usePopupActions } from '../../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import CancelIcon from '@mui/icons-material/Cancel';

type Props = {
  onClose: () => void;
}

export const UploadError = ({ onClose }: Props) => {
  const { setImportProcessStep, setIsUploadingCSVProcess } = usePopupActions()

  const close = () => {
    setImportProcessStep('first');
    setIsUploadingCSVProcess(false)
    onClose();
  }

  const onRetry = () => {
    setImportProcessStep('first');
    setIsUploadingCSVProcess(false)
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.title}>
          Uploading CSV error
        </Box>
        <IconButton aria-label="delete" color="default" onClick={onClose} className={styles.closeButton}>
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>

      <Box className={styles.body}>
        <Box className={styles.body__error}>
          <CancelIcon sx={{ color: '#DB491F' }} />
          <Typography className={styles.body__errorText}>
            The file List.1234USA.csv is too big. It includes 15 000 leads.
            You have 14 000 leads left. Would like to purchase more space?
          </Typography>
        </Box>
      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={close}
          className={styles.footer__cancel}>
          Close
        </Button>
        <Box>
          <Button variant="contained" className={styles.footer__action} onClick={onRetry}>
            Retry
          </Button>
        </Box>
      </Box>
    </Box >
  );
}

