import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest, postRequest } from "../../../general/https"
import { GetAutomationConfigResponse, RunAutomationPayload, RunAutomationResponse, UpdateConfigPayload, getLeadsToExcludePayload, getLeadsToExcludeResponse } from "./types"
import { checkErrorStatus } from "../../../general/checkErrorStatus"

export const runAutomation = createAsyncThunk<RunAutomationResponse, RunAutomationPayload>(
  'automationPageSlice/runAutomation',
  async (payload, {rejectWithValue}) => {
    try {
      const {tab, isAutomationRunning, excludeLeads} = payload;
      const body = {isAutomationRunning, tabId: tab.id, excludeLeads};
      const response = await postRequest('/automation/run-automation', body ,true);
      checkErrorStatus(response)

      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getAutomationConfig = createAsyncThunk<GetAutomationConfigResponse>(
  'automationPageSlice/getAutomationConfig',
  async (_, {rejectWithValue}) => {
    try {
      const response = await getRequest('/automation/get-automation-config', true)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateConfig = createAsyncThunk<GetAutomationConfigResponse, UpdateConfigPayload>(
  'automationPageSlice/updateConfig',
  async (body, {rejectWithValue}) => {
    try {
      const response = await postRequest('/automation/update-automation-config', body, true)
      checkErrorStatus(response)

      if(response) {
        const res = await getRequest('/automation/get-automation-config', true)
        checkErrorStatus(response)
        return res.data
      }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getLeadsToExclude = createAsyncThunk<getLeadsToExcludeResponse, getLeadsToExcludePayload>(
  'automationPageSlice/getLeadsToExclude',
  async (body, {rejectWithValue}) => {
    try {
      const response = await postRequest('/lead-information/get-leads-to-exclude-automation', body, true)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
