import { Box, Button, IconButton, Typography } from '@mui/material';
import styles from "../ImportCSV.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { usePopupActions } from '../../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { usePopupData } from '../../../../utils/redux/storeHooks/dataHooks/usePopupData';

type Props = {
  onClose: () => void;
}

export const UploadNoSlots = ({ onClose }: Props) => {
  const { setImportProcessStep } = usePopupActions()
  const { errorForNoSlots } = usePopupData();

  const retry = () => {
    setImportProcessStep('first')
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.title}>
          Import CSV
        </Box>
        <IconButton aria-label="delete" color="default" onClick={onClose} className={styles.closeButton}>
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>

      <Box className={styles.body}>
        <Box className={styles.body__noSlots}>
          <WarningIcon sx={{ color: '#db491f', fontSize: 22 }} />
          <Typography className={styles.body__text}>
            {errorForNoSlots}
          </Typography>
        </Box>
      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={onClose}
          className={styles.footer__cancel}>
          Cancel upload
        </Button>
        <Box>
          <Button className={styles.footer__action} onClick={retry}>
            Retry
          </Button>
        </Box>
      </Box>
    </Box >
  );
}

