import { Box, Slider } from '@mui/material';
import * as React from 'react';
import { useAutomationData } from '../../../../utils/redux/storeHooks/dataHooks/useAutomationData';
import { NameOfSlider } from '../../../../types/pages/automationPage/NameOfSlider';
import { valuetext } from '../../../../utils/pages/automationPage/valueText';
import scss from '../../automation_page.module.scss'

type Props = {
  handleChangeSlider: (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
    name: string
  ) => void;
}

export const AutomationPageDelayBetweenAutomation = ({ handleChangeSlider }: Props) => {
  const { delayAutomation, } = useAutomationData();

  return (
    <>
      <Slider
        getAriaLabel={() => 'Minimum distance shift'}
        value={delayAutomation}
        disabled
        onChange={(event, newValue, activeThumb) => {
          handleChangeSlider(event, newValue, activeThumb, NameOfSlider.delayAutomation)
        }}
        valueLabelDisplay="auto"
        min={5}
        step={1}
        max={60}
        getAriaValueText={valuetext}
        disableSwap
        sx={{ color: '#3B2470' }}
      />
      <Box className={scss.setting__footer}>
        <Box className={scss.setting__footerTitle}>
          From:
          <Box className={scss.setting__parametr}>
            {delayAutomation[0]}
          </Box>
          to:
          <Box className={scss.setting__parametr}>
            {delayAutomation[1]}
          </Box>
          min.
        </Box>
      </Box>
    </>
  )
}

