import React, { useEffect } from 'react';
import styles from "./RunAutomation.module.scss";
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Box } from '@mui/material';
import { HeaderPopUp } from './features/HeaderPopUp';
import { ActionsPopUp } from './features/ActionsPopUp';
import { AllContent } from './features/AllContent';
import { FooterPopUp } from './features/FooterPopUp';
import { useAutomationData } from '../../../utils/redux/storeHooks/dataHooks/useAutomationData';
import { ExcludedContent } from './features/ExcludedContent';

export const PopUpRunAutomation = () => {
  const { setPopUpRunAutomation, } = usePopupActions();
  const { isShowOnlyExcluded } = useAutomationData();


  const closePopUp = () => {
    setPopUpRunAutomation(false)
  }

  useEffect(() => {
    document.body.classList.add('popup-open');
    return () => {
      document.body.classList.remove('popup-open');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isShowOnlyExcluded) {
    return (
      <Box className={styles.popup}>
        <HeaderPopUp closePopUp={closePopUp} />
        <Box className={styles.content}>
          <ActionsPopUp />
          <ExcludedContent />
        </Box>
        <FooterPopUp closePopUp={closePopUp} />
      </Box >
    );
  }

  return (
    <Box className={styles.popup}>
      <HeaderPopUp closePopUp={closePopUp} />
      <Box className={styles.content}>
        <ActionsPopUp />
        <AllContent />
      </Box>
      <FooterPopUp closePopUp={closePopUp} />
    </Box >
  );
};
