import * as React from 'react';
import scss from '../automation_page.module.scss'
import { Box, Typography } from '@mui/material';

export const AutomationPageInfo = () => {
  return (
    <Box className={scss.description}>
      <Typography className={scss.partMainTitle}>
        Automation description:
      </Typography>
      <Box className={scss.description__titleWrapper}>
        <Typography className={scss.description__titleWrapper__title}>
          - Heet.ai provides the ability to automatically comment on all your leads using artificial intelligence.
        </Typography>
        <Typography className={scss.description__titleWrapper__title}>
          - You can leave 500 comments per day on your Leads&apos; posts.
        </Typography>

        <Typography className={scss.description__titleWrapper__title}>
          - You can fine-tune the comment-sending process by selecting time delays between comments as well as an overall automation delay.
        </Typography>
      </Box>
    </Box>
  )
}

