import * as React from 'react';
import style from '../../newFeedPage.module.scss'
import { Box, Button, CircularProgress, Zoom } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { MainActionButton } from '../../../../components/actionButton/MainActionButton';
import { useNewFeedData } from '../../../../utils/redux/storeHooks/dataHooks/useNewFeedData';
import { useNewFeedActions } from '../../../../utils/redux/storeHooks/actionsHooks/useNewFeedActions';
import { useParams } from 'react-router-dom';
import { TooltipApp } from '../../../../components/tooltipApp/TooltipApp';
import { options } from '../../../../utils/pages/newFeedPage/daysOptions';
import { CreateNewTabPayload, UpdateTabPayload } from '../../../../utils/redux/slices/newFeedSlice/types';
import { isKeywordsEqualWithInitial } from '../../../../utils/pages/newFeedPage/isKeywordsEqualWithInitial';
import { isDaysOptionEqualWithInitial } from '../../../../utils/pages/newFeedPage/isDaysOptionEqualWithInitial';
import { useAutomationData } from '../../../../utils/redux/storeHooks/dataHooks/useAutomationData';
import { usePopupActions } from '../../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { useAutomationActions } from '../../../../utils/redux/storeHooks/actionsHooks/useAutomationActions';
import { useLeadsNotificationData } from '../../../../utils/redux/storeHooks/dataHooks/useLeadsNotificationData';

export const ActionButtons = () => {
  const { tabId } = useParams();
  const { tabs, initialTabs } = useNewFeedData()
  const {
    automationTab,
    isAutomationRunning,
    isRunAutomationRequest,
    excludeLeadsList
  } = useAutomationData();
  const { runAutomation } = useAutomationActions();
  const { createNewTab, updateTab, clearAllFiltersForTab } = useNewFeedActions();
  const { isSavingTab, isUpdatingTab, totalFeedCount } = useNewFeedData();
  const { setPopUpRunAutomation } = usePopupActions();
  const { isNotificationUpdating } = useLeadsNotificationData()

  const currentTab = tabs.find(tab => tab.id === tabId)
  const currentInitialTab = initialTabs.find(tab => tab.id === tabId)
  const isLimitTabs = tabs.length >= 6;

  const isClearFiltersButtonVisible = currentTab.includeKeywords.length > 0
    || currentTab.excludeKeywords.length > 0
    || currentTab.daysOption.value !== options[options.length - 1].value

  const isFiltersEqualWithInitial = () => {
    return (
      isKeywordsEqualWithInitial(currentInitialTab.includeKeywords, currentTab.includeKeywords)
      && isKeywordsEqualWithInitial(currentInitialTab.excludeKeywords, currentTab.excludeKeywords)
      && isDaysOptionEqualWithInitial(currentInitialTab.daysOption.value, currentTab.daysOption.value)
    )
  }

  const saveFilters = () => {
    const generalTab = tabs.find(tab => tab.id === 'general')
    const newTab: CreateNewTabPayload = {
      body: {
        includeKeywords: generalTab.includeKeywords,
        excludeKeywords: generalTab.excludeKeywords,
        daysOption: generalTab.daysOption,
        title: `New tab ${tabs.length}`
      },
      from: 0,
      to: 10
    }
    createNewTab(newTab)
  }

  const updateFilters = () => {
    const tabToUpdate = tabs.find(tab => tab.id === tabId)
    const updatedTab: UpdateTabPayload = {
      id: tabToUpdate.id,
      includeKeywords: tabToUpdate.includeKeywords,
      excludeKeywords: tabToUpdate.excludeKeywords,
      daysOption: tabToUpdate.daysOption,
    }
    updateTab(updatedTab)
  }

  const handleOpenPopUpRunAutomation = () => {
    setPopUpRunAutomation(true)
  }

  const handleStopAutomation = () => {
    runAutomation({
      isAutomationRunning: false,
      tab: automationTab,
      excludeLeads: excludeLeadsList.map(lead => lead.leadIdentitySN)
    })
  }

  const setMainActionType = () => {
    if (tabId === 'general') {
      return {
        title: 'Save filters',
        action: saveFilters
      }
    }

    return {
      title: 'Update filters',
      action: updateFilters
    }
  }
  const mainActionType = setMainActionType()

  return (
    <Box className={style.actions__actionButtonWrapper}>
      {isClearFiltersButtonVisible && (
        <Button
          className={style.actions__clearAllFilters}
          variant="text"
          onClick={() => clearAllFiltersForTab({ tabId })}
        >
          Clear all filters
        </Button>
      )}
      {
        isLimitTabs ? (
          <TooltipApp title={
            <>
              <span className={style.toolTipBody}>
                <img src='/tooltip_icon.svg' alt='' />
                You cannot add more than 5 filters
              </span>
            </>
          }>
            <Box>
              <MainActionButton
                title={mainActionType.title}
                width={125}
                height={42}
                actionOnClick={mainActionType.action}
                isLoading={isSavingTab || isUpdatingTab}
                isDisabled
              />
            </Box>
          </TooltipApp>
        )
          : (
            <MainActionButton
              title={mainActionType.title}
              width={125}
              height={42}
              actionOnClick={mainActionType.action}
              isLoading={isSavingTab || isUpdatingTab}
              isDisabled={
                currentTab.includeKeywords.length > 5
                || currentTab.excludeKeywords.length > 5
                || isSavingTab
                || isUpdatingTab
                || isFiltersEqualWithInitial()
              }
            />
          )
      }

      {
        isNotificationUpdating
          ? (
            <TooltipApp TransitionComponent={Zoom} placement="bottom-start" title={
              <>
                <span >
                  Please wait while notifications update.
                </span>
              </>
            }>
              <Box>
                <Button
                  className={style.actions__runAutomation}
                  variant="contained"
                  onClick={isAutomationRunning ? handleStopAutomation : handleOpenPopUpRunAutomation}
                  disabled={(isAutomationRunning && String(automationTab?.id) !== tabId)
                    || isRunAutomationRequest
                    || !totalFeedCount
                    || isNotificationUpdating
                  }
                >
                  {(isAutomationRunning && String(automationTab?.id) === tabId)
                    ? (
                      <>
                        <CircularProgress
                          size={24}
                          color='inherit'
                          sx={{ marginRight: '5px' }}
                        />
                        Stop automation
                      </>)
                    : (
                      <>
                        <SyncIcon />
                        Run automation
                        {isRunAutomationRequest && <CircularProgress
                          size={24}
                          color='inherit'
                          sx={{ position: 'absolute' }}
                        />}
                      </>
                    )}
                </Button>
              </Box>
            </TooltipApp>
          )
          : (
            <Button
              className={style.actions__runAutomation}
              variant="contained"
              onClick={isAutomationRunning ? handleStopAutomation : handleOpenPopUpRunAutomation}
              disabled={(isAutomationRunning && String(automationTab?.id) !== tabId)
                || isRunAutomationRequest
                || !totalFeedCount
                || isNotificationUpdating
              }
            >
              {(isAutomationRunning && String(automationTab?.id) === tabId)
                ? (
                  <>
                    <CircularProgress
                      size={24}
                      color='inherit'
                      sx={{ marginRight: '5px' }}
                    />
                    Stop automation
                  </>)
                : (
                  <>
                    <SyncIcon />
                    Run automation
                    {isRunAutomationRequest && <CircularProgress
                      size={24}
                      color='inherit'
                      sx={{ position: 'absolute' }}
                    />}
                  </>
                )}
            </Button>
          )
      }

      {/* <Button
        className={style.actions__runAutomation}
        variant="contained"
        onClick={isAutomationRunning ? handleStopAutomation : handleOpenPopUpRunAutomation}
        disabled={(isAutomationRunning && String(automationTab?.id) !== tabId)
          || isRunAutomationRequest
          || !totalFeedCount
        }
      >
        {(isAutomationRunning && String(automationTab?.id) === tabId)
          ? (
            <>
              <CircularProgress
                size={24}
                color='inherit'
                sx={{ marginRight: '5px' }}
              />
              Stop automation
            </>)
          : (
            <>
              <SyncIcon />
              Run automation
              {isRunAutomationRequest && <CircularProgress
                size={24}
                color='inherit'
                sx={{ position: 'absolute' }}
              />}
            </>
          )}
      </Button> */}
    </Box>
  )
}
