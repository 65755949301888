import { Box, Button, CircularProgress, CircularProgressProps, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { usePopupActions } from '../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { useTableLeadsData } from '../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import { useTableLeadsActions } from '../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import { SnUploadingStep } from '../../types/pages/dashboardLeadsPeoplePage/snUploadingStep';
import { useLocation } from 'react-router-dom';

const CircularProgressWithLabel = (
  props: CircularProgressProps & { value: number },
) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color='inherit'
          sx={{ fontFamily: 'Kanit', fontSize: '10px' }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export const UploadingSnLinkIndicator = () => {
  const { setPopUpStopParsingLeads } = usePopupActions();
  const { getUploadingSnLinkForUser, sendErrorNotification } = useTableLeadsActions();
  const { uploadingSnLink, isSendingNotificationError } = useTableLeadsData();
  const pathname = useLocation().pathname;

  const getPercent = () => {
    if (uploadingSnLink && uploadingSnLink.uploadingStep === SnUploadingStep.parsingLead) {
      const uploadedLeads = uploadingSnLink.leadInformation;
      const totalLeadsCount = uploadingSnLink.totalLeadsCount;
      if (typeof uploadedLeads === 'number') {
        return (uploadedLeads / totalLeadsCount) * 100
      }
    }
  }

  const openPopUp = () => {
    setPopUpStopParsingLeads(true)
  }

  const onSendErrorNotification = () => {
    sendErrorNotification({
      snLinkId: uploadingSnLink.id
    })
  }

  useEffect(() => {
    getUploadingSnLinkForUser();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  if (uploadingSnLink?.isUploadingError) {
    return (
      <Box
        sx={{
          backgroundColor: '#FFB7A3',
          color: '#191F2E',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          padding: '8px 12px',
          fontFamily: 'Kanit',
          boxShadow: 'none',
          borderRadius: '1px',
          '&:hover': {
            backgroundColor: '#FFB7A3',
          }
        }}

      >
        <Box sx={{
          fontFamily: 'Kanit',
          display: 'flex',
          alignItems: 'center',
          gap: '6px',
          fontSize: 16,
          color: '#191F2E',
        }}>
          <ErrorIcon sx={{ color: '#191F2E' }} />
          {`Error when processed ${uploadingSnLink.uploadingStep === SnUploadingStep.parsingLead ? 'Leads' : 'Feed'}. Please notify admin: `}
        </Box>

        <Button
          onClick={onSendErrorNotification}
          variant='contained'
          sx={{
            display: 'flex',
            width: '128px',
            gap: '4px',
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: '#fff',
            }
          }}>
          {
            isSendingNotificationError
              ? (
                <CircularProgress sx={{ color: '#191F2E' }} size={22} />
              )
              : (
                <Typography sx={{ fontFamily: 'Kanit', fontSize: 16, color: '#191F2E' }}>
                  notify admin
                </Typography>
              )
          }
        </Button>
      </Box>
    )
  }

  if (uploadingSnLink?.isUploading && uploadingSnLink?.uploadingStep === SnUploadingStep.parsingLead) {
    return (
      <Box
        sx={{
          backgroundColor: '#BFE8B1',
          color: '#191F2E',
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          padding: '8px 12px',
          fontFamily: 'Kanit',
          boxShadow: 'none',
          borderRadius: '1px',
          '&:hover': {
            backgroundColor: '#BFE8B1',
          }
        }}
      >
        <CircularProgressWithLabel sx={{ color: '#191F2E' }} size={34} value={getPercent() || 0} />
        <Typography sx={{ fontFamily: 'Kanit', fontSize: 16, color: '#191F2E' }}>
          Processing your leads
        </Typography>
        <Button
          onClick={openPopUp}
          variant='contained'
          sx={{
            display: 'flex',
            gap: '4px',
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: '#fff',
            }
          }}>
          <CancelIcon sx={{ color: '#191F2E' }} />
          <Typography sx={{ fontFamily: 'Kanit', fontSize: 16, color: '#191F2E' }}>
            stop
          </Typography>
        </Button>
      </Box>
    )
  }

  if (uploadingSnLink?.isUploading && uploadingSnLink?.uploadingStep === SnUploadingStep.parsingFeed) {
    return (
      <Box
        sx={{
          backgroundColor: '#E0E8F9',
          color: '#191F2E',
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          padding: '8px 12px',
          fontFamily: 'Kanit',
          boxShadow: 'none',
          borderRadius: '1px',
          '&:hover': {
            backgroundColor: '#E0E8F9',
          }
        }}
      >
        <CircularProgress sx={{ color: '#191F2E' }} size={22} />
        <Typography sx={{ fontFamily: 'Kanit', fontSize: 16, color: '#191F2E' }}>
          Processing feed
        </Typography>
        <Button
          onClick={openPopUp}
          variant='contained'
          sx={{
            display: 'flex',
            gap: '4px',
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: '#fff',
            }
          }}>
          <CancelIcon sx={{ color: '#191F2E' }} />
          <Typography sx={{ fontFamily: 'Kanit', fontSize: 16, color: '#191F2E' }}>
            stop
          </Typography>
        </Button>
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'none' }}></Box>
  )
}

