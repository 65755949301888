import { configureStore } from '@reduxjs/toolkit'
import { tableLeadsReducer } from './slices/tableLeadsSlice/tableLeadsSlice'
import { notificationReducer } from './slices/notificationSlice/notificationSlice'
import { popupReducer } from './slices/popupSlice/popupSlice'
import { sidebarReducer } from './slices/sidebarSlice/sidebarSlice'
import { tableTeamManagementReducer } from './slices/tableTeamManagmentSlice/teamManagementSlice'
import { tableImportCSVReducer } from './slices/tableImportCSVSlice/tableImportCSVSlice'
import { newFeedReducer } from './slices/newFeedSlice/newFeedSlice'
import { automationReducer } from './slices/automationPageSlice/automationSlice'
import { botManagementReducer } from './slices/botManagementSlice/botManagementSlice'
import { leadsNotificationReducer } from './slices/leadsNotificationSlice/leadsNotificationSlice'
import { adminPageReducer } from './slices/adminPageSlice/adminPageSlice'

export default configureStore({
  reducer: {
    tableLeadsReducer,
    notificationReducer,
    popupReducer,
    sidebarReducer,
    tableTeamManagementReducer,
    tableImportCSVReducer,
    newFeedReducer,
    automationReducer,
    botManagementReducer,
    leadsNotificationReducer,
    adminPageReducer
  },
  devTools: process.env.REACT_APP_ENV !== 'prod',
})