import { useAppSelector } from '../typedHooks';

export const usePopupData = () => {
  const {
    isPopupAddLeadOpen,
    isPopupSendMessageOpen,
    iceBreakerMsg,
    leadConnectionPosition,
    userFirstLastName,
    profileImg,
    textOfPost,
    isHaveNewActivity,
    leadId,
    isPopUpImportCSVOpen,
    importProcessStep,
    isUploadingCSVProcess,
    errorForNoSlots,
    isPopupRenameTabOpen,
    isPopUpDeleteTabOpen,
    isPopUpAddBotOpen,
    isPopUpAddLeadsUseSnOpen,
    isPopUpStopParsingLeadsOpen,
    isPopUpRunAutomationOpen,
    isMessageGenerating,
    isMessageSending,
    isPopUpManageUserOpen,
    isPopUpSendExtensionOpen,
    isPopUpSendCredentialOpen,
    isPopUpDeleteUserOpen,
    isPopUpDeleteTeamOpen,
    isPopUpCreateTeamOpen,
    isPopUpUpdateTeamOpen,
    isPopUpExportLeadsOpen
  } = useAppSelector((state) => state.popupReducer);

  return {
    isPopupAddLeadOpen,
    isPopupSendMessageOpen,
    iceBreakerMsg,
    leadConnectionPosition,
    userFirstLastName,
    profileImg,
    textOfPost,
    isHaveNewActivity,
    leadId,
    isPopUpImportCSVOpen,
    importProcessStep,
    isUploadingCSVProcess,
    errorForNoSlots,
    isPopupRenameTabOpen,
    isPopUpDeleteTabOpen,
    isPopUpAddBotOpen,
    isPopUpAddLeadsUseSnOpen,
    isPopUpStopParsingLeadsOpen,
    isPopUpRunAutomationOpen,
    isMessageGenerating,
    isMessageSending,
    isPopUpManageUserOpen,
    isPopUpSendExtensionOpen,
    isPopUpSendCredentialOpen,
    isPopUpDeleteUserOpen,
    isPopUpDeleteTeamOpen,
    isPopUpCreateTeamOpen,
    isPopUpUpdateTeamOpen,
    isPopUpExportLeadsOpen
  };
};