import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import styles from "../ImportCSV.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React from 'react';
import { usePopupActions } from '../../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { usePopupData } from '../../../../utils/redux/storeHooks/dataHooks/usePopupData';

type Props = {
  onClose: () => void;
}

export const UploadFirstStep = ({ onClose }: Props) => {
  const { uploadCSV } = usePopupActions();
  const { isUploadingCSVProcess } = usePopupData();

  const downloadExample = () => {
    const link = document.createElement("a");
    link.download = `example.csv`;
    link.href = "/example.csv";
    link.click();
  };

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0]
    if (file) {
      uploadCSV({ file })
    }
  };

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.title}>
          Import CSV
        </Box>
        <IconButton aria-label="delete" color="default" onClick={onClose} className={styles.closeButton}>
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>

      <Box className={styles.body}>
        <Typography className={styles.body__text}>
          To ensure the files fits the criteria, you can use example file.
        </Typography>
        <Button
          component='button'
          className={styles.body__downloadButton}
          onClick={downloadExample}
        >
          <img src='/download.svg' alt='' />
          <Typography className={styles.body__downloadButtonText}>
            Download sample CSV file
          </Typography>
        </Button>
      </Box>
      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={onClose}
          className={styles.footer__cancel}>
          Cancel
        </Button>


        <Button className={styles.footer__action} component="label" disabled={isUploadingCSVProcess}>
          {isUploadingCSVProcess
            ? <CircularProgress size={22} sx={{ color: 'white' }} /> : (
              <>
                <img src='/upload_icon.svg' alt='' />
                Import CSV
                <input
                  type="file"
                  accept=".xls, .xlsx, .csv"
                  hidden
                  onChange={(event) => handleUpload(event)}
                />
              </>
            )}
        </Button>

      </Box>
    </Box >
  );
}

