import { Statuses } from "../../../types/components/uploadingCSVIndicator/statuses"

export const getVariantButton = (status: string) => {
  switch (status) {
    case Statuses.Uploaded:
      return { backgroundColor: '#E0E8F9', color: '#3B2470 ', text: 'Uploading CSV...' }  
    case Statuses.Parsing:
      return { backgroundColor: '#BFE8B1', color: '#191F2E', text: 'CSV Uploaded. Processing your leads... ' }
    default:
      return { backgroundColor: '#E0E8F9', color: '#3B2470', text: 'Uploading...' }
    }
} 